import logo from './logo.svg';
import './App.css';
import axios from "axios";
import {useEffect, useState} from "react";

function App() {
  const [backend_response, setBackendResponse] = useState(null)
  useEffect( () => {
      axios.get('https://wabash-poc-be.dev-apps.io/').then(response => {
        setBackendResponse(response.data)
      }).catch(error=>{
        console.log(error)
      })
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Below are the response from the backend::: <code> {backend_response} </code>
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
